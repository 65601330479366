import './Footer.scss';
import { Button } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer mt-3">
        <div className="grid">
          <div className="col mt-00 mr-0">
            <h4 className="tw normal-text">Dove siamo</h4>
            <p className="tw mt-0 p0px">Via Nazionale 33</p>
            <p className="tw mt-0 p0px">34151 Trieste (TS)</p>
            <p className="tw mt-0 p0px">Italia</p>
            <Button size="lg" href="https://www.google.com/maps/dir//P.I.O.V.E.+Di+Goldin+Silvio/data=!4m8!4m7!1m0!1m5!1m1!1s0x477b14b13c313e47:0xea8fc51ec7db81eb!2m2!1d13.78648!2d45.68593">
              Indicazioni Stradali
            </Button>
          </div>
          <div className="col mt-00 mr-0">
            <h4 className="tw normal-text">Contatti</h4>
            <p className="tw mt-0 p0px">Email: pioveopicina@gmail.com</p>
            <p className="tw mt-0 p0px">Telefono: 040-213828</p>
            <div className="cta-1 mt-0 mb-1">
              <Button size="lg" href="tel:+39-040-213828">
                Chiama Ora
              </Button>
            </div>
          </div>
          <div className="col mt-00 mr-0">
            <h4 className="tw normal-text">Orari</h4>
            <p className="tw mt-0 mb-0 p0px">lunedì: chiuso</p>
            <p className="tw mt-0 mb-0 p0px">martedì: 8.30-12.30, 15.30-19.30</p>
            <p className="tw mt-0 mb-0 p0px">mercoledì: 8.30-12.30, 15.30-19.30</p>
            <p className="tw mt-0 mb-0 p0px">giovedì: 8.30-12.30, 15.30-19.30</p>
            <p className="tw mt-0 mb-0 p0px">venerdì: 8.30-12.30, 15.30-19.30</p>
            <p className="tw mt-0 mb-0 p0px">sabato: 8.30-12.30, 15.30-19.30</p>
            <p className="tw mt-0 mb-0 p0px">domenica: chiuso</p>
          </div>
        </div>
        <div className="grid">
          <div className="copyright mt-sma-foot">
            <small className="copyright__text tw p0px">© 2023 PIOVE di Goldin Silvio</small>
            <small className="copyright__text tw mt-0 p0px">Credits: Matteo Goldin</small>
          </div>
        </div>
      </footer>
  );
}

export default Footer;