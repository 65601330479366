import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faAt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState, useRef } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

import "./Contact.scss";
import axios from "axios";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const userRef = useRef();
  const recaptchaRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    if (email === "") return true;
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      setErrMessage("Compila tutti i campi");
      this.recaptcha.reset();
    } else if (!validateEmail(email)) {
      setErrMessage("Email non valida");
      this.recaptcha.reset();
    } else {
      setErrMessage("");
      recaptchaRef.current.execute();
    }
  };

  const sendEmail = async () => {
    const response = await axios.post(
      "https://api.pioveopicina.it/mail",
      JSON.stringify({
        name: name,
        email: email,
        message: message,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.pioveopicina.it/mail",
        },
      }
    );

    if (response.status === 200) {
      console.log("Email inviata");
      setSuccessMessage("Email inviata");
      await new Promise((r) => setTimeout(r, 3000));
      navigate("/");
    } else {
      console.log("Errore nell'invio dell'email");
      setErrMessage("Errore nell'invio dell'email");
    }

    setErrMessage("");
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="contact">
      {errMessage ? (
        <Alert variant="danger" className="contact-alert">
          {errMessage}
        </Alert>
      ) : null}
      {successMessage ? (
        <Alert variant="success" className="contact-alert">
          {successMessage}
        </Alert>
      ) : null}
      <div className="contact__content">
        <div className="contact-info">
          <h1>Contattaci</h1>
          <p className="tw">
            Compila il form e invia. Risponderemo il prima possibile!
          </p>
          <div>
            <FontAwesomeIcon
              icon={faMapLocationDot}
              style={{ color: "#ffffff" }}
            />
            <p className="tw">Via Nazionale 33, Opicina (TS)</p>
          </div>
          <div>
            <FontAwesomeIcon icon={faAt} style={{ color: "#ffffff" }} />
            <p className="tw">pioveopicina@gmail.com</p>
          </div>
          <div>
            <FontAwesomeIcon icon={faPhone} style={{ color: "#ffffff" }} />
            <p className="tw">040-213828</p>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faFacebookF}
              size="lg"
              style={{ color: "#ffffff" }}
            />
            <Button variant="link" style={{ color: "#ffffff" }}>
              Seguici su fb
            </Button>
          </div>
        </div>

        <div className="line"></div>

        <div className="form-container">
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="tw">Nome</Form.Label>
              <Form.Control
                ref={userRef}
                type="text"
                value={name}
                placeholder="Inserisci il tuo nome"
                onChange={(e) => setName(e.target.value)}
              />

              <Form.Label className="tw">Email</Form.Label>
              <Form.Control
                ref={userRef}
                type="email"
                value={email}
                placeholder="Inserisci la tua email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <Form.Label className="tw">Messaggio</Form.Label>
              <Form.Control
                ref={userRef}
                as="textarea"
                rows={3}
                placeholder="Inserisci il tuo messaggio"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Invia
            </Button>
          </Form>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdRLSQcAAAAAAZ3SB8cbSEkIWu-0mgki7p0bN6k"
        onChange={sendEmail}
      />
    </div>
  );
};

export default Contact;
